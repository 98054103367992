import { Box } from '@achieve/ascend'
import { useInlineMemoizedContentGetter } from 'utils/contentful'
import { isEmpty as _isEmpty } from 'lodash-es'
import { LinkButton, MediaImageStandard, Typography } from 'components/Contentful'
import { Eyebrow } from 'components/Eyebrow'
import { IconListItem } from 'components/IconListItem'
import { BLOCKS } from '@contentful/rich-text-types'
import styles from './CardProductListItem.module.scss'

const CardProductListItem = ({ content, index }) => {
  const { cardTitleText, cardLink, cardImage, iconCardItemCollection, eyebrow } =
    useInlineMemoizedContentGetter(content, [
      'cardTitleText',
      'cardLink',
      'cardImage',
      'iconCardItemCollection',
      'eyebrow',
    ])

  if (_isEmpty(content)) return null

  return (
    <Box key={`item-${index}`} className={styles['cards-list-item']}>
      <Box className={styles['cards-list-item-image']}>
        <MediaImageStandard priority={true} content={cardImage} layout="responsive" />
      </Box>
      {eyebrow && (
        <Eyebrow
          className={styles['cards-list-item-eyebrow']}
          content={eyebrow}
          variant="bodySm"
          fontWeight="bold"
          black
        />
      )}

      {cardTitleText && <Typography content={cardTitleText} variant="bodyLg" fontWeight="medium" />}

      <Box className={styles['cards-list-item-icons']}>
        {iconCardItemCollection?.items?.map((icon, iconIdx) => {
          return (
            <Box className={styles['cards-list-item-icons-item']} key={`item-icon-${iconIdx}`}>
              <IconListItem
                key={`icon-list-item-${iconIdx}`}
                cardTitleString={cardTitleText}
                iconColor={icon.iconColor}
                iconName={icon.iconName}
                itemText={icon.description}
                classNameIcon={styles['icon']}
                iconSize={20}
                noMarginBottom={iconIdx + 1 === iconCardItemCollection?.items?.length}
              />
            </Box>
          )
        })}
      </Box>

      {cardLink && (
        <LinkButton
          className={styles['button']}
          track={{
            ...{
              list_name: 'Recommended Card Link',
              click_position: index >= 0 ? index + 1 : '0',
              click_id:
                cardLink.fields?.linkText?.json.content[0]?.content[0]?.value ?? 'Product Cards',
              click_text:
                cardLink.fields?.linkText?.json.content[0]?.content[0]?.value ?? 'Product Cards',
              click_url: cardLink?.linkHref,
            },
            ...cardLink?.event,
          }}
          content={cardLink}
          typographicOptions={{
            variantOverride: { [BLOCKS.PARAGRAPH]: 'bodyLg' },
            fontWeight: 'bold',
          }}
          color="primary"
          variant={LinkButton.VARIANTS.CONTAINED}
          fullWidth={true}
          data-testid={`product-cards-section-card-button-text-${cardTitleText?.json?.content?.[0]?.content[0]?.value}`}
        />
      )}
    </Box>
  )
}
export { CardProductListItem }
